<template>
    <div class="create-upload-component">
        <el-upload
            class="upload-demo"
            v-if="uploadFileList.length === 0"
            multiple
            :accept="acceptType"
            :drag="true"
            :limit="uploadFileLimitSize"
            :show-file-list="false"
            :http-request="onUploadEvent"
            :before-upload="beforeUploadEvent"
            :on-exceed="uploadFileExceedLimit"
            :action="uploadFileUrl"
            :headers="uploadHeaders">
            <div class="upload-main">
                <div class="image-icon">
                    <svg-icon name="ib-upload-o"></svg-icon>
                </div>
                <div class="type-icon-list">
                    <span><svg-icon name="ic-word"></svg-icon></span>
                    <span><svg-icon name="ic-excel"></svg-icon></span>
                    <span><svg-icon name="ic-ppt"></svg-icon></span>
                    <span><svg-icon name="ic-rtf"></svg-icon></span>
                    <span><svg-icon name="ic-csv"></svg-icon></span>
                    <span><svg-icon name="ic-txt"></svg-icon></span>
                    <span><svg-icon name="ic-srt"></svg-icon></span>
                    <span><svg-icon name="ic-sdlxliff"></svg-icon></span>
                    <span><svg-icon name="ic-xliff"></svg-icon></span>
                    <span><svg-icon name="ic-pdf" v-if="hasPdf"></svg-icon></span>
                    <span><svg-icon name="ic-zip"></svg-icon></span>
                </div>
                <div class="title-2">
                    <template v-if="userInfo&&userInfo.featureConfig">
                        {{ $t('project.component_CreateUpload_tips_1', {count: uploadFileLimitSize, size: userInfo.featureConfig.project.singleFileSize}) }}
                    </template>
                    <span>
                        <el-tooltip placement="top" :content="uploadFileMessage">
                            <div slot="content">
                                {{ uploadFileMessage }}<br><template v-if="hasPdf">{{$t('project.component_CreateUpload_tips_2')}}</template>
                            </div>
                            <svg-icon name="ib-question"></svg-icon>
                        </el-tooltip>
                    </span>
                </div>
            </div>
        </el-upload>
        <div class="files-content" v-if="uploadFileList.length > 0">
            <div class="files-main">
                <div class="infos">
                    <div class="image-icon">
                        <svg-icon name="ib-upload-o"></svg-icon>
                    </div>
                    <div class="type-icon-list">
                        <span><svg-icon name="ic-word"></svg-icon></span>
                        <span><svg-icon name="ic-excel"></svg-icon></span>
                        <span><svg-icon name="ic-ppt"></svg-icon></span>
                        <span><svg-icon name="ic-rtf"></svg-icon></span>
                        <span><svg-icon name="ic-csv"></svg-icon></span>
                        <span><svg-icon name="ic-txt"></svg-icon></span>
                        <span><svg-icon name="ic-srt"></svg-icon></span>
                        <span><svg-icon name="ic-sdlxliff"></svg-icon></span>
                        <span><svg-icon name="ic-xliff"></svg-icon></span>
                        <span><svg-icon name="ic-pdf" v-if="hasPdf"></svg-icon></span>
                        <span><svg-icon name="ic-zip"></svg-icon></span>
                        <span>
                            <el-tooltip placement="top" :content="uploadFileMessage">
                                <div slot="content">{{ uploadFileMessage }}<br/><template v-if="hasPdf">{{$t('project.component_CreateUpload_tips_2')}}</template></div>
                                <svg-icon name="ib-question" style="color:#00000066;"></svg-icon>
                            </el-tooltip>
                        </span>
                    </div>
                </div>
                <div class="files-list">
                    <div class="item" v-for="(file, index) in uploadFileList" :key="index">
                        <div class="file-type">
                            <svg-icon :name="'ic-' + file.fileType.icon"></svg-icon>
                        </div>
                        <div class="name">
                            <el-tooltip :content="file.name" placement="top">
                                <span>{{ file.name }}</span>
                            </el-tooltip>
                        </div>
                        <div class="progress">
                            <span class="fonts-16" style="color: #d7343f" v-if="file.status === 'fail'">
                                <svg-icon name="ib-error-o"></svg-icon>
                            </span>
                            <span class="fonts-16" style="color: #00b670" v-else-if="file.status === 'success'">
                                <svg-icon name="ib-check-circle"></svg-icon>
                            </span>
                            <span v-else>{{ file.percentage }}%</span>
                        </div>
                        <div class="delete-btn" @click="deleteFileEvent(index)">
                            <svg-icon name="ib-close"></svg-icon>
                        </div>
                    </div>
                </div>
                <div class="upload-more">
                    <!-- <span>共 {{ uploadFileList.length }} 个文件</span> -->
                    <span>{{ $t('project.component_CreateUpload_tips_3', {count1: successSize, count2: failSize}) }}</span>
                    <div class="upload-btn">
                        <el-upload
                            multiple
                            :accept="acceptType"
                            :action="uploadFileUrl"
                            :limit="uploadFileLimitSize"
                            :show-file-list="false"
                            :http-request="onUploadEvent"
                            :on-exceed="uploadFileExceedLimit"
                            :before-upload="beforeUploadEvent"
                            :headers="uploadHeaders">
                            <el-button type="text">{{$t('project.component_CreateUpload_tips_4')}}</el-button>
                        </el-upload>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import $ from 'jquery';
import md5 from 'md5';
import cookie from 'vue-cookies';
import config from '@/utils/config';
import { mapState, mapGetters } from 'vuex';
import { projectApi } from '@/utils/api';
export default {
    name: 'CreateUpload',
    props: {

    },
    data(){
        return {
            uploadFileUrl: projectApi.uploadSingleFile, //projectApi.uploadMultipleFile
            acceptType: `.docx,.doc,.rtf,.xlsx,.xls,.ppt,.pptx,.srt,.txt,.csv,.xliff,.xlf,.sdlxliff,.sdlxlf,.zip,.rar,.7z,.vtt,.vsdx`,
			uploadFileMessage: this.$t('project.component_CreateUpload_data_uploadFileMessage', {types: 'docx/doc/rtf/xlsx/xls/ppt/pptx/srt/txt/csv/xliff/xlf/sdlxliff/sdlxlf/zip/rar/7z/vtt/vsdx'}),
			uploadFileLimitSize: 20, //上传最大文件数
			uploadHeaders: {
				Authorization: cookie.get("token"),
			},
            uploadFileList: [],
            fileData: {},
            hasPdf: false,
        }
    },
    computed: {
        ...mapState({
            uploadToken: state => state.common.uploadToken,
		}),
        ...mapGetters({
            userInfo: 'user/userInfo',
        }),
        successSize() {
            return this.uploadFileList.filter(file => file.status === 'success').length;
        },
        failSize() {
            return this.uploadFileList.filter(file => file.status === 'fail').length;
        },
    },
    methods: {
        getMd5Name(file){
            let names = file.name.split('.');
            let type = '.'+names[names.length-1];
            let noTypeNames = names.slice(0, names.length-1);
            let noTypeName = noTypeNames.join('.');
            let noTypeNameMd5 = md5(noTypeName);
            return noTypeNameMd5 + type;
        },
        beforeUploadEvent(file) {
            let names = file.name.toLocaleLowerCase().split(".");
            let type = "." + names[names.length - 1];
			console.log(`[INFO] %cMIME TYPE: %c${file.type}; %cFILE FORMAT: %c${type}`, 'font-weight:bolder', '', 'font-weight:bolder', '');
            let nameMd5 = this.getMd5Name(file);
            let acceptTypes = this.acceptType.split(',');
            //文件类型验证
			if (acceptTypes.indexOf(type) < 0) {
				this.$message.error(this.uploadFileMessage);
				return false;
			}
            //文件个数验证
			if (this.uploadFileList.length === this.uploadFileLimitSize) {
				this.$message.error(this.$t('project.component_CreateUpload_method_beforeUploadEvent_message_1', {count: this.uploadFileLimitSize}));
				return false;
			}
            if(file.size === 0){
                this.$message.error(this.$t('project.component_CreateUpload_method_beforeUploadEvent_message_2'));
                return false;
            }
            //文件大小验证
            if(file.size > this.userInfo.featureConfig.project.singleFileSize * 1024 * 1024){
                this.$message.error(this.$t('project.component_CreateUpload_method_beforeUploadEvent_message_3', {fileName: file.name, size: this.userInfo.featureConfig.project.singleFileSize}));
                return false;
            }
			if (!this.hasFile(file)) {
				this.uploadFileList.push({
					name: file.name,
                    nameMd5,
					uid: file.uid,
					size: file.size,
					percentage: 0,
					raw: file,
					fileType: config.FILE_TYPE_MATCH[type],
					status: "",
				});
			} else {
				this.$message.error(this.$t('project.component_CreateUpload_method_beforeUploadEvent_message_4', {fileName: file.name}));
				return false;
			}

		},
		uploadFileExceedLimit(files, fileList) {
			this.$message.error(this.$t('project.component_CreateUpload_method_beforeUploadEvent_message_1', {count: this.uploadFileLimitSize}));
		},
        onUploadEvent(param){
            // console.log('onUploadEvent::', param);
            let file = param.file;
            let nameMd5 = this.getMd5Name(file);
            let config = {
                onUploadProgress: (progressEvent) => {
                    let  complete = ((progressEvent.loaded / progressEvent.total) * 100) | 0;
                    file.percentage = complete;
                    this.updateUploadFileList(file);
                }
            }
            
            this.$store.dispatch('common/initUploadTokenAction').then(async res => {
                const uploadToken = res;
                let params = {
                    ...uploadToken.formData,
                    key: uploadToken.prefixKey + nameMd5,
                    file: param.file,
                };
                this.$ajax.fileUpload(uploadToken.host, params, config).then(res =>{
                    file.status = 'success';
                    this.updateUploadFileList(file);
                    let tmpData = this.fileData;
                    this.fileData = {
                        ...tmpData, 
                        [file.uid]: {
                            fileName: file.name,
                            objectName: uploadToken.prefixKey + nameMd5,
                            size: file.size,
                        }
                    }; 
                    this.$nextTick(()=>{
                        this.$emit('action', 'updateFile', {
                            fileList: this.uploadFileList,
                            fileData: this.fileData,
                        });
                    });
                }).catch(err =>{
                    file.status = 'fail';
                    this.updateUploadFileList(file);
                    this.$nextTick(()=>{
                        this.$emit('action', 'updateFile', {
                            fileList: this.uploadFileList,
                            fileData: this.fileData,
                        });
                    });
                });
            });
            
        },

		uploadProgressEvent(e, file, fileList) {
			this.updateUploadFileList(file);
		},
		uploadSuccessEvent(response, file, fileList) {
			this.updateUploadFileList(file);
			let tmpData = this.fileData ? this.fileData : {};
			this.fileData = { ...tmpData, [file.uid]: response.data.docs[0] };
            // this.$set(this.form, 'originValue', response.data.sourceLang);
            let lang = response.data.sourceLang;
            if (lang === 'unknown'){
                lang = 'zh';
            }
            this.$emit('action', 'updateLang', {
                originValue: lang,
                targetValue: lang !== "zh" ? "zh" : "en",
            });
            this.$nextTick(()=>{
                this.$emit('action', 'updateFile', {
                    fileList: this.uploadFileList,
                    fileData: this.fileData,
                });
            });
            
			// this.form.originValue = lang;
			// this.form.targetValue = lang !== "zh" ? "zh" : "en";
		},
		uploadErrorEvent(err, file, fileList) {
			this.updateUploadFileList(file);
            this.$nextTick(()=>{
                this.$emit('action', 'updateFile', {
                    fileList: this.uploadFileList,
                    fileData: this.fileData,
                });
            });
			let res = JSON.parse(err.message);
			if (err.status === 401) {
				this.$message.error(this.$t('project.component_CreateUpload_method_beforeUploadEvent_message_5'));
				this.$cookies.remove("token");
				localStorage.setItem("redirect", location.href);
				setTimeout(() => {
					location.replace(`/login`);
				}, 1000);
			} else {
				this.$message.error(res.message || this.$t('project.component_CreateUpload_method_beforeUploadEvent_message_6'));
			}
		},
		updateUploadFileList(file) {
			let index = -1;
			for (let i = 0; i < this.uploadFileList.length; i++) {
				if (file.uid === this.uploadFileList[i].uid) {
					index = i;
					break;
				}
			}
			if (index !== -1) {
				this.uploadFileList.splice(index, 1, {
					...this.uploadFileList[index],
					...file,
					percentage: parseInt(file.percentage),
					status: file.status,
				});
			}
		},
		hasFile(file) {
			let rst = this.uploadFileList.filter(item => item.name === file.name && item.size === file.size);
			return rst.length > 0;
		},
		deleteFileEvent(index) {
			let file = this.uploadFileList[index];
			this.uploadFileList.splice(index, 1);
			delete this.fileData[file.uid];
            this.$nextTick(()=>{
                this.$emit('action', 'updateFile', {
                    fileList: this.uploadFileList,
                    fileData: this.fileData,
                });
            });
		},
        getValue(){
            return {
                fileList: this.uploadFileList,
                fileData: this.fileData,
            }
        },
        initDraggerAnimation() {
			let node = $(".create-upload-component");
			node.on("dragenter", function (ev) {
				if (
					($(ev.target).hasClass(".upload-demo") ||
						$(ev.target).parents(".upload-demo").length > 0) &&
					!node.hasClass("dragger-animation")
				) {
					node.addClass("dragger-animation");
				}
			});
			node.on("dragleave", function (ev) {
				if (
					!$(ev.target).hasClass(".upload-demo") &&
					$(ev.target) &&
					$(ev.target).parents(".upload-demo").length === 0
				) {
					node.removeClass("dragger-animation");
				}
			});
			node.on("drop", function (ev) {
				node.removeClass("dragger-animation");
			});
		},
    },
    mounted() {
        this.initDraggerAnimation();
        if(['personal', 'pro-limited'].indexOf(this.userInfo.accountType) < 0) {
            this.hasPdf = true;
            this.acceptType = `.docx,.doc,.rtf,.xlsx,.xls,.ppt,.pptx,.srt,.txt,.csv,.xliff,.xlf,.sdlxliff,.sdlxlf,.pdf,.zip,.rar,.7z,.html,.htm,.dita,.idml,.ditamap,.vtt,.vsdx`;
            this.uploadFileMessage =  this.$t('project.component_CreateUpload_data_uploadFileMessage', {types: 'docx/doc/rtf/xlsx/xls/ppt/pptx/srt/txt/csv/xliff/xlf/sdlxliff/sdlxlf/pdf/zip/rar/7z/html/htm/dita/idml/ditamap/vtt/vsdx'});
        }
        this.uploadFileLimitSize = this.userInfo.featureConfig.project.fileCount;
    }
}
</script>
<style lang="scss" scoped>
@keyframes uploadWave {
	0% {
		width: 0%;
		height: 0%;
	}
	25% {
		width: 25%;
		height: 25%;
	}
	50% {
		width: 50%;
		height: 50%;
	}
	75% {
		width: 75%;
		height: 75%;
	}
	100% {
		width: 100%;
		height: 100%;
	}
}
.create-upload-component{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &.dragger-animation {
        .upload-demo {
            .el-upload-dragger {
                &::after {
                    animation: uploadWave 1.5s linear infinite;
                }
            }
        }
    }
    .upload-main {
        .image-icon {
            transition: margin-top 0.3s;
            img {
                width: 99px;
                height: 76px;
            }
            svg {
                font-size: 90px;
                color: #000000;
            }
        }
        .type-icon-list {
            margin-top: 20px;
            span {
                margin-right: 10px;
                &:last-child {
                    margin-right: 0;
                }
                svg {
                    font-size: 20px;
                }
            }
        }
        .title-1 {
            margin-top: 20px;
            font-size: 20px;
            color: #000;
        }
        .title-2 {
            margin-top: 30px;
            font-size: 17px;
            color: #000;
            span {
                margin-left: 15px;
                cursor: pointer;
                svg {
                    color: #00000066;
                    font-size: 18px;
                }
            }
        }
        &.has-file {
            .image-icon {
                margin-top: 90px;
            }
        }
    }
    .files-content {
        width: 580px;
        height: 580px;
        background-color: #f2f2f2;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        > .files-main {
            width: 380px;
            > .infos {
                > .image-icon {
                    text-align: center;
                    svg {
                        font-size: 73px;
                    }
                }
                > .type-icon-list {
                    text-align: center;
                    margin-top: 10px;
                    span {
                        margin-right: 10px;
                        &:last-child {
                            margin: 0;
                        }
                        svg {
                            font-size: 20px;
                        }
                    }
                }
            }
            > .files-list {
                width: 100%;
                max-height: 342px;
                margin-top: 20px;
                overflow-y: auto;
                &::-webkit-scrollbar {
                    width: 5px;
                }
                > .item {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    height: 44px;
                    border-bottom: 1px solid #0000001a;
                    box-sizing: border-box;
                    font-size: 12px;
                    min-width: 0;
                    margin-right: 10px;
                    > .file-type {
                        svg {
                            font-size: 24px;
                        }
                    }
                    > .progress {
                        // margin-left: 10px;
                        // width: 37px;
                    }
                    > .name {
                        flex: 1;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        margin: 0 10px;
                    }
                    .delete-btn {
                        margin-left: 10px;
                        cursor: pointer;
                        display: none;
                        svg {
                            font-size: 12px;
                            color: #00000066;
                            &:hover {
                                color: #000;
                            }
                        }
                    }
                    &:hover {
                        .delete-btn {
                            display: block;
                        }
                    }
                }
            }
            > .upload-more {
                margin-top: 20px;
                text-align: center;
                font-size: 12px;
                > .upload-btn {
                    display: inline-block;
                    margin-left: 10px;
                    .el-button--text {
                        color: #00b670;
                        font-size: 12px;
                    }
                }
            }
        }
    }
    .upload-demo {
        ::v-deep .el-upload-dragger {
            border: none;
            width: 580px;
            height: 580px;
            background-color: #f2f2f2;
            border-radius: 50%;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            &::after {
                content: "";
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                pointer-events: none;
                background-color: #e5e5e54d;
                border-radius: 50%;
                transform: translate(-50%, -50%);
            }
            &:hover {
                border: none;
            }
            &:hover:after {
                animation: uploadWave 1.5s linear infinite;
            }
        }
    }
}
</style>
